import "./Popup.css";
import closeIcon from "../../assets/close.svg";

const Popup = (props) => {
  return (
    <div className="popup-container">
      <div className="Popup">
        <div className="popup-header">
          <div className="popup-title">{props.title}</div>
          <div className="popup-icon">
            <img onClick={props.handleClose} src={closeIcon} alt="close icon" />
          </div>
        </div>
        <div className="popup-text">{props.content}</div>
      </div>
    </div>
  );
};

export default Popup;
