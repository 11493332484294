import React, { useState } from "react";
import "./Navbar.css";
import UsFlag from "../../assets/us.svg";
import FrFlag from "../..//assets/fr.svg";
import Popup from "../Popup/Popup";
import Overlay from "../Popup/Overlay";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const togglePopup = () => {
    setIsPopupOpened(!isPopupOpened);
  };

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  function changeToFrench() {
    i18n.changeLanguage("fr");
  }

  function changeToEnglish() {
    i18n.changeLanguage("en");
  }

  return (
    <>
      <div className="Navbar">
        <div className="navbar-app-name">
          <Link to="/">{t("french-to-ipa")}</Link>
        </div>
        <div className="navbar-actions">
          <div className="navbar-actions-home">
            <Link to="/">{t("home")}</Link>
          </div>
          <a
            className="navbar-actions-sponsor"
            target="_blank"
            rel="noreferrer"
            href="https://PayPal.Me/maiscodes"
          >
            {t("donate")}
          </a>
          <div className="navbar-actions-privacy">
            <Link to="/privacy-policy">{t("privacy")}</Link>
          </div>
          <div className="navbar-actions-about" onClick={togglePopup}>
            {t("about")}
          </div>
          <div className="navbar-actions-contact">
            <Link to="/contact">{t("contact")}</Link>
          </div>
          <div className="navbar-actions-lang">
            <img
              src={UsFlag}
              alt="change to English"
              onClick={changeToEnglish}
            />
            <img src={FrFlag} alt="change to French" onClick={changeToFrench} />
          </div>
        </div>
      </div>
      {isPopupOpened && <Overlay handleClose={togglePopup} />}
      {isPopupOpened && (
        <Popup
          title={t("about-this-website")}
          content={<p>{t("about-this-website-details")}</p>}
          handleClose={togglePopup}
        />
      )}
    </>
  );
};

export default Navbar;
