import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import Navbar from "./components/Navbar/Navbar";
import IPA from "./components/IPA/IPA";
import Privacy from "./components/Privacy/Privacy";
import Contact from "./components/Contact/Contact";

import "./App.css";

function App() {
  const { t } = useTranslation();

  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="container">
          <div className="header">
            <div className="title">{t("french-to-ipa")}</div>
          </div>
          <Routes>
            <Route path="/" element={<IPA />} exact />
            <Route path="/privacy-policy" element={<Privacy />} exact />
            <Route path="/contact" element={<Contact />} exact />
          </Routes>
        </div>
        {/* <Footer /> */}
        <Analytics />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4049638118943578"
     crossorigin="anonymous"></script>
      </div>
    </Router>
  );
}

export default App;
