import "./IPA.css";
import React, { useState } from "react";
import dict from "../../assets/fr_FR.json";
import { useTranslation } from "react-i18next";

const IPA = () => {
  const [result, setResult] = useState(null);
  const [word, setWord] = useState("");
  const { t } = useTranslation();

  function getIPA() {
    let words = word.split(/[\s\n]+/);
    let localResult = "";

    if (words.length >= 1) {
      for (let word of words) {
        // remove punctuation
        word = word.replaceAll(/[.,?!]/g, "");
        if (dict[word.toLowerCase()] !== undefined) {
          // case for multiple notations per word. e.g. "est":"/ɛst/, /ɛ/"
          let parsed_value = dict[word.toLowerCase()].split(",")[0];
          let temp = JSON.stringify(parsed_value).replaceAll('"', "");
          localResult += temp + " ";
        }
      }
    }
    setResult(localResult);
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      getIPA();
    }
  };

  return (
    <div className="main">
      <textarea
        type="text"
        name="word"
        className="input-space"
        onChange={(e) => setWord(e.target.value)}
        placeholder={t("input-french-words")}
        onKeyDown={handleKeyDown}
      />
      <input
        className="button"
        type="submit"
        onClick={getIPA}
        value={t("find-words") + "→"}
      />
      <div className="result">{result}</div>
    </div>
  );
};

export default IPA;
