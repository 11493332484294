import "./Privacy.css";
import i18n from "../../i18n/config";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();
  const contentArray = [];

  for (let i = 1; i <= 8; i++) {
    const policy_title = t(`privacy-policy-details.title${i}`);
    const policy_content = t(`privacy-policy-details.content${i}`);
    contentArray.push(
      <div className="privacy-section" key={i}>
        <h2>{policy_title}</h2>
        <p>{policy_content}</p>
      </div>
    );
  }
  return <div className="privacy-container">{contentArray}</div>;
};

export default Privacy;
